import React from 'react'
import { graphql } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

// Module
import AnchorLink from 'react-anchor-link-smooth-scroll'

// Components
import GraphQLErrorList from '../components/graphql-error-list'
import Layout from '../containers/layout'
import SEO from '../components/seo'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      subtitle
    }

    home: sanityHome(_id: { regex: "/(drafts.|)homePage/" }) {
      title
      title1
      body
      slide1Image {
        asset {
          _id
        }
      }
      title2
      body2
      slide2Image1 {
        asset {
          _id
        }
      }
      slide2Image2 {
        asset {
          _id
        }
      }
      title3
      body3
      slide3Image {
        asset {
          _id
        }
      }
      body4
      ctaText
      ctaLink
    }
  }
`

// Classes
const ParagraphClass = 'dib relative w-100 measure-wide dark-gray fw4 mb4'
const HeadingClass = 'dib relative w-100 measure f2 fw5 mv3'

class IndexPage extends React.Component {
  render() {
    const { data, errors } = this.props

    const site = (data || {}).site
    const home = (data || {}).home

    if (!home) {
      throw new Error(
        'Missing "Home" page data. Open the studio at http://localhost:3333 and add "Home" page data and restart the development server.'
      )
    }

    if (errors) {
      return (
        <Layout>
          <GraphQLErrorList errors={errors} />
        </Layout>
      )
    } else {
      return (
        <Layout>
          <SEO title={site.title} description={site.description} keywords={site.keywords} />
          <article id="home">
            {/* Home */}
            <section
              className="dt relative vh-100 w-100 white lh-copy f5 patterns pattern-1"
              id="home"
            >
              <div className="dtc v-mid w-100 relative">
                <div className="db center mw8 pa4">
                  <div className="dib relative w-100">
                    <h1 hidden>{site.title}</h1>
                    <div className="dib relative flex-l justify-between w-100 bottom-xs">
                      <img
                        className="dib relative v-btm w-60 w-40-l mb4 mb0-l"
                        alt="Friedmans"
                        src="/img/logotype-white.svg"
                      />
                      <p className="dib relative v-btm f3 f-subtitle tl tr-l ma0">
                        {site.subtitle}
                      </p>
                    </div>
                  </div>
                  <AnchorLink
                    className="dib absolute bottom-0 right-0 left-0 w-100 tc pa4"
                    href="#mission"
                  >
                    <img
                      className="dib relative w1-5 h1-5 tr bounce"
                      src="/img/scroll-down-white.svg"
                      alt="Scroll down"
                    />
                  </AnchorLink>
                </div>
              </div>
            </section>

            {/* Mission */}
            <section className="dib relative bg-white w-100 black lh-copy f4" id="mission">
              <div className="db center mw8 ph4 pt5 pb4">
                <div className="row w-100">
                  <div className="col-xs-12 col-md-6">
                    <h2 className={HeadingClass}>{home.title1}</h2>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <p className={ParagraphClass}>{home.body}</p>
                  </div>
                </div>
              </div>
              {home.slide1Image && home.slide1Image.asset && home.slide1Image.asset._id ? (
                <img
                  className="dib relative w-100 pb2"
                  src={imageUrlFor(buildImageObj(home.slide1Image))}
                  alt={home.title1}
                />
              ) : null}
              {/* Established */}
              <div className="db center mw8 ph4 pv4">
                <div className="row w-100">
                  <div className="col-xs-12 col-md-6">
                    <strong className="dib relative w-100 f4 b mt3">{home.title2}</strong>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <p className={ParagraphClass}>{home.body2}</p>
                  </div>
                </div>
              </div>
              {/* Images */}
              <div className="dib flex-l justify-between top-xs w-100">
                {home.slide2Image1 && home.slide2Image1.asset && home.slide2Image1.asset._id ? (
                  <div
                    className="dib relative fit dual-images w-100 w-80-l mb0 mr4-l"
                    style={{
                      background: `url(${imageUrlFor(
                        buildImageObj(home.slide2Image1)
                      )}) no-repeat center center`,
                      backgroundSize: 'cover'
                    }}
                  >
                    &nbsp;
                  </div>
                ) : null}
                {home.slide2Image2 && home.slide2Image2.asset && home.slide2Image2.asset._id ? (
                  <div
                    className="dib relative fit dual-images w-100 w-80-l mb0"
                    style={{
                      background: `url(${imageUrlFor(
                        buildImageObj(home.slide2Image2)
                      )}) no-repeat center center`,
                      backgroundSize: 'cover'
                    }}
                  >
                    &nbsp;
                  </div>
                ) : null}
              </div>
            </section>

            {/* Attention */}
            <section className="dib relative bg-white w-100 black lh-copy f4" id="attention">
              <div className="db center mw8 ph4 pt5 pb4 pb6-l">
                <h3 className={HeadingClass}>{home.title3}</h3>
                <div className="row mt4 mb0 mb0-l">
                  <div className="col-xs-12 col-md-4">
                    {home.slide3Image && home.slide3Image.asset && home.slide3Image.asset._id ? (
                      <img
                        className="dib relative fit w-100 h-100-l mb4 mb0-l"
                        src={imageUrlFor(buildImageObj(home.slide3Image))}
                        alt={home.title1}
                      />
                    ) : null}
                  </div>
                  <div className="col-xs-12 col-md-8">
                    <div className="dib relative w-100 pl0 pl4-l">
                      <p className={`${ParagraphClass} mt0`}>{home.body3}</p>
                      <p className={`${ParagraphClass} f5 preline`}>{home.body4}</p>
                      <a
                        className="dib relative w-100 underline inherit f5"
                        href={`tel:${home.ctaLink}`}
                      >
                        {home.ctaText}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </Layout>
      )
    }
  }
}

export default IndexPage
